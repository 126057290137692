<template>
  <div>
    <Header background="#223F9C" :notScroll="true" />
    <div class="dynamicDetail_containers">
      <div class="dynamicDetail_cont">
        <p class="dynamic_title">{{ newsDetail.title }}</p>
        <p class="dynamic_time_source">
          <span>发布日期：{{ newsDetail.created_at }}</span>
          <span class="source">来源：{{ newsDetail.source }}</span>
        </p>
        <div class="dynamic_msg" v-html="newsDetail.body"></div>
      </div>
      <div class="dynamic_list">
        <p class="list_title">相关动态</p>
        <div class="list">
          <template v-for="(item, index) in newsList">
            <p class="item" :key="index" @click.stop="seeNewsDetail(item)">
              {{ item.title }}
            </p>
          </template>
        </div>
      </div>
    </div>
    <SuspensionConsult />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import SuspensionConsult from "@/components/suspensionConsult";
import { newsListApi, newsDetailApi } from "@/api";
export default {
  components: {
    Header,
    Footer,
    SuspensionConsult,
  },
  data() {
    return {
      newsDetail: {},
      newsList: [],
    };
  },
  watch: {
    "$route.query.newsId"(newVal) {
      this.getNewsDetail();
    },
  },
  mounted() {
    this.getNewsDetail();
    this.getNewsList();
  },
  methods: {
    async getNewsDetail() {
      const res = await newsDetailApi(this.$route.query.newsId);
      if (res.code == 0) {
        this.newsDetail = res.data;
      }
    },
    async getNewsList() {
      const res = await newsListApi();
      if (res.code == 0) {
        this.newsList = res.data.items;
      }
    },
    seeNewsDetail(e) {
      this.$router.push({
        query: {
          newsId: e.id,
        },
      });
    },
  },
};
</script>

<style lang="scss">
html {
  background: #f0f2f5;
}
</style>
<style lang="scss" scoped>
.dynamicDetail_containers {
  width: 100%;
  padding: 70px 208px 109px 208px;
  display: flex;

  .dynamicDetail_cont {
    flex: 1;
    overflow: hidden;

    .dynamic_title {
      @include regularFont(30px, #4a4a4a);
    }

    .dynamic_time_source {
      margin-top: 20px;
      @include regularFont(14px, #aaaaaa);

      .source {
        margin-left: 20px;
      }
    }

    .dynamic_msg {
      margin-top: 25px;
    }
  }

  .dynamic_list {
    width: 395px;
    height: fit-content;
    background: #fafafa;
    border-radius: 1px;
    margin-left: 35px;
    padding: 12px;
    padding-bottom: 28px;

    .list_title {
      @include regularFont(15px, #4a4a4a);
    }

    .list {
      margin-top: 20px;
      padding-left: 17px;

      .item {
        margin-top: 32px;
        position: relative;
        cursor: pointer;
        @include regularFont(13px, #4a4a4a);

        &:first-child {
          margin-top: 0;
        }

        &::before {
          content: "";
          width: 7px;
          height: 7px;
          border-radius: 50%;
          background: #4483f4;
          position: absolute;
          left: -17px;
          top: 5px;
        }

        &:hover {
          color: #4483f4;
        }
      }
    }
  }
}
</style>